
import { defineComponent } from "vue";
import i18n from "@/lang";
const locale = i18n.global.locale.value;

export default defineComponent({
  props: {
    showFakeCS: Boolean,
    fakeCsContent: String,
  },

  emits: {
    openCS: () => true,
    closeFakeCS: () => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFakeInput: (v: string) => true,
  },
  data() {
    return {};
  },
  methods: {
    openCustomerService() {
      this.$emit("openCS");
    },
    close() {
      this.$emit("closeFakeCS");
    },
    setInput(e: Event) {
      this.$emit("setFakeInput", (e.target as HTMLTextAreaElement).value);
    },
  },
  render() {
    return (
      <van-popup class="van-modal" show={this.showFakeCS}>
        <div class="container">
          <img src={require(`./assets/${locale}/image.png`)} />
          <div class="close-btn" onClick={this.close}></div>
          <textarea
            class="input"
            value={this.fakeCsContent}
            onInput={this.setInput}
            placeholder={this.$t("download.saySomething")}
          ></textarea>
          <div class="enter-btn" onClick={this.openCustomerService}></div>
        </div>
      </van-popup>
    );
  },
});
