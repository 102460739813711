
import { defineComponent } from "vue";
import { util } from "dev-sdk";
import i18n from "@/lang";
import Tutorial from "./components/tutorial/index.vue";
import CustomerService from "./components/customer-service/index.vue";
import FakeCustomerService from "./components/fake-customer-service/index.vue";
import { getService } from "@/service";
import Country from "@/models/country";
import MarkSixPage from "./pages/mark-six-page.vue";

const { VUE_APP_CITY } = process.env;
const locale = i18n.global.locale.value;

export default defineComponent({
  data() {
    return {
      isLoading: false,
      android: "",
      ios: "",
      h5: "",
      customerServiceDomain: "https://mchat.bm1sdgic10.com",
      showModal: false,
      showFakeCS: false,
      isClickBtn: false,
      fakeCsContent: "",
    };
  },

  async created() {
    await this.getDownload();
  },

  computed: {
    bgSrc() {
      if (Country.isJapan && Country.supportedLangs.includes(locale)) {
        return require(`./assets/bg-${Country.code}-${locale}.jpg`);
      }
      if (VUE_APP_CITY) {
        return require(`./assets/${locale}/bg-${VUE_APP_CITY}.jpg`);
      }
      return require(`./assets/${locale}/bg.jpg`);
    },
  },

  methods: {
    async getDownload() {
      const data = await getService().global.downloadUrl();
      if (!data) {
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.android = data.android;
      this.ios = data.ios;
      this.h5 = data.h5;
      if (data.customerService) {
        this.customerServiceDomain = data.customerService;
      }
    },
    openModal() {
      this.showModal = true;
      this.closeAutoShowFakeCS();
    },
    closeModel() {
      this.showModal = false;
    },
    goSponsor(name: string) {
      this.closeAutoShowFakeCS();
      const h5Domain = this.h5.replace("www", "m");
      const lang = locale === "zh" ? "zh-CN" : locale;
      window.open(`${h5Domain}/sponsor-detail/${name}?lang=${lang}`, "_blank");
    },
    goH5() {
      this.closeAutoShowFakeCS();
      const h5Domain = this.h5.replace("www", "m");
      const lang = locale === "zh" ? "zh-CN" : locale;
      // window.open(`${h5Domain}?lang=${lang}`, "_blank");
      window.location.href = `${h5Domain}?lang=${lang}`;
    },
    downloadApp() {
      this.closeAutoShowFakeCS();
      if (util.OS.iPhone) {
        const link = `itms-services://?action=download-manifest&url=${this.ios}`;
        window.location.href = link;
        return;
      }
      window.location.href = this.android;
    },
    setFakeInput(v: string) {
      this.fakeCsContent = v;
    },
    closeAutoShowFakeCS() {
      this.isClickBtn = true;
    },
    closeFakeCS() {
      this.showFakeCS = false;
    },
    autoOpenFakeCS() {
      if (!this.isClickBtn) {
        this.showFakeCS = true;
        this.closeAutoShowFakeCS();
      }
    },
    openCS() {
      this.showFakeCS = false;
      const csObj: any = this.$refs.customerService;
      csObj.openCustomerService();
    },
    renderSponsorContent() {
      if (Country.isJapan && Country.supportedLangs.includes(locale)) {
        return null;
      }
      const sponsorMap = ["cannavaro", "pierce"];
      return (
        <div class="sponsor-content">
          {sponsorMap.map((item) => {
            return (
              <a
                class="sponsor-btn"
                onClick={this.goSponsor.bind(this, item)}
              ></a>
            );
          })}
        </div>
      );
    },
  },

  render() {
    const { locale } = this.$i18n;
    if (locale === "zh") {
      return (
        <div class="app__mark-six-page">
          <MarkSixPage
            onCustomerService={() =>
              (this.$refs.customerService as any).openCustomerService()
            }
            onPrimaryBtnClick={this.downloadApp}
            onBtnClick={this.goH5}
          />
          <CustomerService
            ref="customerService"
            customerServiceDomain={this.customerServiceDomain}
            content={this.fakeCsContent}
            onCloseAutoShowFakeCS={this.closeAutoShowFakeCS}
            btnVisible={false}
          />
        </div>
      );
    }
    return (
      <div class="bty-seo">
        {this.isLoading ? (
          <div class="cover">
            <img class="loading" src={require("./assets/loading.gif")} />
          </div>
        ) : null}

        <Tutorial show={this.showModal} onCloseModal={this.closeModel} />

        <CustomerService
          ref="customerService"
          customerServiceDomain={this.customerServiceDomain}
          content={this.fakeCsContent}
          onCloseAutoShowFakeCS={this.closeAutoShowFakeCS}
        />

        <FakeCustomerService
          showFakeCS={this.showFakeCS}
          fakeCsContent={this.fakeCsContent}
          onOpenCS={this.openCS}
          onCloseFakeCS={this.closeFakeCS}
          onSetFakeInput={this.setFakeInput}
        />

        <div class="img-content">
          <img class="bg" src={this.bgSrc} />
          {this.renderSponsorContent()}
          <div class="title-content">
            {[...new Array(6).keys()].map(() => {
              return <a class="title-btn" onClick={this.goH5}></a>;
            })}
          </div>
        </div>

        <div class="footer fresh">
          <div class="home-btn btn" onClick={this.downloadApp}>
            {this.$t("download.install")}
          </div>
          <a class="go-h5" onClick={this.goH5}>
            {this.$t("download.goH5")}
          </a>
          {locale === "zh" ? (
            <p class="protect">
              为了您的隐私安全，请勿与“国家反诈中心”APP同设备使用
            </p>
          ) : null}
          <div class="ios-tutorial" onClick={this.openModal}>
            {this.$t("download.openTutorial")}
            {">"}
          </div>
        </div>
      </div>
    );
  },
});
