const { DEFAULT_COUNTRY } = process.env;

export default class Country {
  static isJapan = DEFAULT_COUNTRY === "jp";
  static code = DEFAULT_COUNTRY;
  static get supportedLangs() {
    if (Country.isJapan) {
      return ["ja", "en"];
    }
    return [];
  }
}
