
import { defineComponent } from "vue";

type SlideShape = {
  src: string;
  alt: string;
};

const SLIDES: SlideShape[] = [
  {
    src: require("../assets/slides/slide-1.png"),
    alt: "六合彩独家授权发行，精准内幕资料，每期免费领取",
  },
  {
    src: require("../assets/slides/slide-2.png"),
    alt: "香港内幕，一肖三码，精准内幕资料点击领取",
  },
  {
    src: require("../assets/slides/slide-3.png"),
    alt: "精采评论",
  },
  {
    src: require("../assets/slides/slide-4.png"),
    alt: "顶尖体育平台，精彩无处不在",
  },
];

export default defineComponent({
  emits: {
    primaryBtnClick: () => true,
    btnClick: () => true,
    customerService: () => true,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  render() {
    const { currentIndex } = this;
    return (
      <div class="mark-six-page">
        <div class="mark-six-page__top">
          <img
            class="mark-six-page__logo"
            src={require("../assets/logo-bsports.png")}
            alt="B SPORTS"
          />
          <a
            class="mark-six-page__btn-cs"
            onClick={() => this.$emit("customerService")}
          >
            <img
              src={require("../assets/icon-customer-service.svg")}
              alt="Customer Service"
            />
          </a>
        </div>
        <van-swipe
          ref="swipe"
          class="mark-six-page__swipe"
          lazy-render
          show-indicators={false}
          onChange={(index: number) => (this.currentIndex = index)}
        >
          {SLIDES.map((slide) => (
            <van-swipe-item class="mark-six-page__slide">
              <img class="mark-six-page__img" src={slide.src} alt={slide.alt} />
            </van-swipe-item>
          ))}
        </van-swipe>
        <div class="mark-six-page__bottom">
          <div class="mark-six-page__indicators">
            {SLIDES.map((row, i) => {
              const active = i === currentIndex;
              const classname = active ? "active" : "";
              return (
                <div
                  class={classname}
                  onClick={() => (this.$refs.swipe as any).swipeTo(i)}
                />
              );
            })}
          </div>
          <button
            class="mark-six-page__primary-btn"
            onClick={() => this.$emit("primaryBtnClick")}
          >
            APP下载
          </button>
          <button
            class="mark-six-page__btn"
            onClick={() => this.$emit("btnClick")}
          >
            进入网页版
          </button>
        </div>
      </div>
    );
  },
});
