
import { defineComponent } from "vue";
import i18n from "@/lang";
const locale = i18n.global.locale.value;

export default defineComponent({
  props: {
    show: Boolean,
  },
  emits: {
    closeModal: () => true,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
  render() {
    return (
      <van-popup class="van-modal" show={this.show}>
        <div class="ios-modal-content">
          <div class="title">
            <p class="ios-title">{this.$t("download.iosTitle")}</p>
            <p class="ios-subtitle">{this.$t("download.subTitle")}</p>
          </div>

          <div class="container">
            <img src={require(`./assets/${locale}/image.png`)} />
          </div>

          <div class="modal-footer">
            <div class="btn" onClick={this.closeModal}>
              {this.$t("download.gotIt")}
            </div>
          </div>
        </div>
      </van-popup>
    );
  },
});
