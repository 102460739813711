import JSEncrypt from "encryptlong";
import _ from "lodash";

const publicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDbWe+gtTWgQYB3IHxY0GU UDEq0otgy0Cl/MNGGXBud4f8Mf86Spj8oWIuAMGaSm730OGzEaR5vxO5niG+stETi n78ZWZUT/xIPC9Zr3k5GhQk4rx6yYVfSenENUlb8f2WEzA5ne+K/sYD8rU13dKUtX7 z8IVKoDeU2E3tHIDwMKQIDAQAB";

function urlSafeEncode(str: string) {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

export function getEnrtyCode(str: any) {
  if (typeof str !== "string") {
    str = JSON.stringify(str);
  }
  const entry = new JSEncrypt();
  entry.setPublicKey(publicKey);
  return urlSafeEncode(entry.encryptLong(str));
}

export function urlParams(data: any) {
  // 定义拼接字符串函数
  let url = "";
  _.forEach(Object.keys(data), (k) => {
    const value = data[k] !== undefined ? data[k] : "";
    url += `${url ? "&" : ""}${k}=${value}`;
  });
  return url;
}
