
import { defineComponent } from "vue";
import i18n from "@/lang";
const locale = i18n.global.locale.value;
import { urlParams, getEnrtyCode } from "@/utils/rsa-encry";

enum LanguageKey {
  zh = "zh_CN",
  en = "en_US",
  vi = "vietnam",
  th = "thai",
  ja = "japanese",
  pt = "portuguese",
  sp = "spanish",
}

// Note: 阿拉伯 70000、土耳其 80000
enum SkillId {
  zh = 0,
  en = 10000,
  vi = 20000,
  th = 30000,
  ja = 40000,
  sp = 50000,
  pt = 60000,
}

interface Data {
  show: boolean;
  customerServiceUrl: string;
  unread: number;
  iframeContent: any;
  showAnimate: boolean;
}

export default defineComponent({
  props: {
    customerServiceDomain: String,
    content: String,
    btnVisible: {
      type: Boolean,
      default: true,
    },
  },
  data(): Data {
    return {
      show: false,
      customerServiceUrl: "",
      unread: 0,
      iframeContent: null,
      showAnimate: false,
    };
  },

  mounted() {
    this.getRead();
  },

  emits: {
    closeAutoShowFakeCS: () => true,
  },
  methods: {
    openCustomerService() {
      this.$emit("closeAutoShowFakeCS");
      const stServiceConfig = {
        productId: "bsports",
        mainColor: "red",
        enterScene: 0,
        DoMain: location.origin,
        lang: LanguageKey[locale],
        isSeoView: true,
        skillId: SkillId[locale],
        content: this.content,
      };
      if (!this.customerServiceUrl) {
        this.customerServiceUrl = `${
          this.customerServiceDomain
        }/login?configKey=${getEnrtyCode(urlParams(stServiceConfig))}`;
      }
      this.show = true;

      setTimeout(() => {
        this.bindIframe();
        this.readMsg();
      }, 1000);
    },
    clickOverlay() {
      this.show = false;
      this.unread = 0;
    },
    getRead() {
      window.addEventListener(
        "message",
        (event) => {
          const unreadCount = event.data.unReadMessageNumber;
          const userExit = event.data.userExit;
          this.unread = unreadCount;
          if (unreadCount > 0) {
            this.showAnimate = true;

            setTimeout(() => {
              this.showAnimate = false;
            }, 1000);
          }
          if (userExit) {
            this.exitCustomerServer();
          }
        },
        false
      );
    },
    readMsg() {
      const { iframeContent } = this;
      iframeContent.contentWindow.postMessage(
        { unReadMessageNumber: "0" },
        "*"
      );
    },
    bindIframe() {
      if (this.iframeContent) return;
      const iFrame: any = document.getElementById("iframe");
      this.iframeContent = iFrame;
    },
    exitCustomerServer() {
      this.customerServiceUrl = "";
      this.iframeContent = null;
      this.show = false;
    },
  },
  render() {
    return (
      <div class="customer-service">
        {this.btnVisible && (
          <div class="area">
            {this.unread ? (
              <div class="unread">
                <span class="count">{this.unread}</span>
              </div>
            ) : null}
            <img
              // class="customer-service-entry"
              {...{
                class: [
                  "customer-service-entry",
                  { animate: this.showAnimate },
                ],
              }}
              src={require("./assets/entry.png")}
              onClick={this.openCustomerService}
            />
          </div>
        )}

        <van-popup
          class="van-modal"
          show={this.show}
          onClickOverlay={this.clickOverlay}
        >
          <div class="content">
            <div class="title">
              <div class="title-text">
                {this.$t("download.customerService")}
              </div>
              <div class="close-area" onClick={this.clickOverlay}>
                <van-icon class="close-icon" name="cross" color="#1F2022" />
              </div>
            </div>

            <iframe
              id="iframe"
              class="iframe-content"
              src={this.customerServiceUrl}
            />
          </div>
        </van-popup>
      </div>
    );
  },
});
