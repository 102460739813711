
import { defineComponent } from "vue";
import { getService } from "@/service";
import { google } from "@/utils/google";

interface AnalyticsItem {
  analyticsId: string;
  type: string;
}

const DynamicApp =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require(`@/apps/${process.env.VUE_APP_SCOPE}/App.vue`).default;

export default defineComponent({
  data() {
    return {
      analyticsList: [],
    };
  },
  async created() {
    await this.getAnalytics();
    await this.insertAnalytics();
  },
  methods: {
    async getAnalytics() {
      const data = await getService().global.analyticsUrl();
      this.analyticsList = data;
    },
    async insertAnalytics() {
      const analyticsList = this.analyticsList as AnalyticsItem[];
      analyticsList.forEach((item) => {
        switch (item.type) {
          case "baidu":
            this.insertBaidu(item.analyticsId);
            break;
          case "cnzz":
            this.insertCnzz(item.analyticsId);
            break;
          case "google":
            this.insertGoogle(item.analyticsId);
            break;
          case "matomo":
            this.insertMatomo(item.analyticsId);
            break;
          case "la":
            this.insertLa(item.analyticsId);
            break;
          default:
        }
      });
    },
    insertBaidu(id: string) {
      var hm = document.createElement("script");
      hm.src = "//hm.baidu.com/hm.js?" + id;
      var s: any = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    },
    insertCnzz(id: string) {
      var hm = document.createElement("script");
      hm.src = "//v1.cnzz.com/z_stat.php?id=" + id + "&web_id=" + id;
      var s: any = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    },
    insertGoogle(id: string) {
      // var hm = document.createElement("script");
      // hm.src = "//www.googletagmanager.com/gtag/js?id=" + id;
      // var s: any = document.getElementsByTagName("script")[0];
      // s.parentNode.insertBefore(hm, s);
      google(id);
    },
    insertMatomo(id: string) {
      var _paq = (window._paq = window._paq || []);
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      var u = "//traffic-analytics.al-hadeed-rollingshutter.in/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", id]);
      var d = document,
        g = d.createElement("script"),
        s: any = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
    },
    insertLa(id: string) {
      var hm = document.createElement("script");
      hm.id = "LA_COLLECT";
      hm.src = "//sdk.51.la/js-sdk-pro.min.js" + "?id=" + id;
      var s: any = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    },
  },
  render() {
    return <DynamicApp />;
  },
});
