import http from "../http";
import { UploadComponent } from "@/utils/download-app";
import { seoDoamin } from "@/utils/domain";
import { AxiosError } from "axios";
import { Notify } from "vant";
import i18n from "@/lang";
export default {
  /**
   * 获取下载地址
   */
  async downloadUrl() {
    const host = window.location.hostname || "miletest.com";
    const protocol = document.location.protocol;
    // const domain = `${seoDoamin()}/seoapi/domain-config/client/?domain=https://zz-seo-test.bsportstest.com`;
    const domain = `${protocol}//api.btyseo.com/seoapi/domain-config/client?domain=${protocol}//${host}`;
    const data = await http.get(domain).catch((e) => {
      if (
        e.code === AxiosError.ERR_NETWORK ||
        e.code === AxiosError.ETIMEDOUT
      ) {
        Notify({
          message: i18n.global.t("notify.timeout"),
          duration: undefined,
          color: "#fff",
          background: "#d42f21",
        });
      }
    });

    return data.data;
  },

  async uploadFingerReport(
    components: UploadComponent,
    hash: string,
    pid = "zz"
  ) {
    const domain = "https://ssapi.iballbet.com";
    const data = await http.post(`${domain}/ssapi/install/register`, {
      ...components,
      hash,
      pid,
    });
    return data;
  },

  /**
   * 获取统计地址
   */
  async analyticsUrl() {
    const host = window.location.hostname || "miletest.com";
    const protocol = document.location.protocol;
    const param = {
      domain: `${protocol}//${host}`,
    };
    const data = await http.post(
      `${seoDoamin()}/seoapi/analytics/search`,
      param
    );
    return data.data.list;
  },
};
